import { Outlet, Link } from "react-router-dom";
import '../App.css'

const Layout = () => {
  return (
    <>
        <Outlet />
    </>
  )
};

export default Layout;
